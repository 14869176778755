import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userLoginReducer, userRegisterReducer } from "./reducers/userReducers";
import { USER_IN_STORAGE } from "./constants/userConstants";
import { ruleListReducer } from "./reducers/signReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  ruleList: ruleListReducer,
});
//loading user from local storage if exists
const userInfoFromStorage = localStorage.getItem(USER_IN_STORAGE)
  ? JSON.parse(localStorage.getItem(USER_IN_STORAGE))
  : null;
const initialState = { userLogin: { userInfo: userInfoFromStorage } };

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
