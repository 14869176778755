import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./designStyle.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./store";
import { Provider } from "react-redux";

import { Spinner } from "react-bootstrap";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    supportedLngs: ["en", "fr"],
    fallbackLng: "en",
    detection: {
      order: ["localStorage", "sessionStorage", "path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: `/assets/locales/{{lng}}/translation.json`,
    },
    //react: { useSuspense: false },
  });
const loadingMarkup = (
  <div className="py-4 text-center">
    <Spinner animation="border" />
  </div>
);

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
