import React from "react";
import { useTranslation } from "react-i18next";
import {
  Stack,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { useSelector } from "react-redux";
import NewsLetter from "../components/forms/NewsLetter";
import Contact from "../components/forms/Contact";

function LandingScreen() {
  const { t } = useTranslation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [email, setEmail] = React.useState("");

  return (
    <div className="minPageHight">
      <body>
        <div class="bannerSection">
          <div class="container">
            <div class="content">
              <p class="bannerHeadingTop">1# {t("best_parking_app")}</p>
              <h1 class="bannerHeading">
                {t("the_solution_to_your")}
                <br />
                {t("parking_needs")}
              </h1>
              <div className="col-md-6 col-sm-12">
                <p class="bannertext">{t("description_park_live_1")}</p>
                <p class="bannertext">{t("parking_simple")}</p>
              </div>

              <p class="bannerIcontext">
                {" "}
                <span class="text-success-ico fw-bold">✓</span>{" "}
                {t("accurate_parking")} |
                <span class="text-success-ico fw-bold">✓</span>{" "}
                {t("always_up_to_date")} |
                <span class="fw-bold text-success-ico">✓</span>{" "}
                {t("24_7_support")}
              </p>
              <div>
                <a href="#">
                  <img src="./img/app_store_icon.png" alt="app store icon" />
                </a>
                <a href="#">
                  <img
                    src="./img/google_play_icon.png"
                    alt="google play icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="container">
            <div class="row bg-green p-5 greenRow">
              <div class="col-6">
                <h1 class="heading_k">{t("heading_2")}</h1>
              </div>
              <div class="col">
                <h1 class="heading_num">
                  570<span class="text-black">K+</span>
                </h1>
                <p class="text-white fw-semibold bodyText">
                  {t("active_users")}
                </p>
              </div>
              <div class="col">
                <h1 class="heading_num">
                  99<span class="text-black">%</span>
                </h1>
                <p class="text-white fw-semibold  bodyText">
                  {t("accurate_data")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="section sectionFeatures" id="features">
          <div class="container">
            <div class="row ">
              <div class="col">
                <img
                  src="./img/image_infront_of_best_featuer.png"
                  alt=""
                  class="featureImage"
                />
              </div>
              <div class="col ">
                <p class="feature-text">{t("our_to_features")}</p>
                <h1 class="feature-heading">{t("unique_solution_parking")}</h1>
                <p style={{ fontSize: "20px", color: "#222d2e;" }}>
                  {t("description_park_live_2")}
                </p>
                <div class="row d-flex justify-content-around">
                  <div class="feature-card m-2 col bg-green">
                    <img
                      src="./img/Path_11541.png"
                      class="card-img-one rounded"
                    />
                    <div class="card-body">
                      <p class="card-text "> {t("parking_availability")} </p>
                    </div>
                  </div>
                  <div class="feature-card m-2 col ">
                    <img
                      src="./img/Path_11543.png"
                      class="card-img-two rounded"
                    />
                    <div class="card-body">
                      <p class="card-text r">{t("parking_restrictions")}</p>
                    </div>
                  </div>
                  <div class="feature-card m-2 col ">
                    <img
                      src="./img/Path_11545.png"
                      class="card-img-two rounded"
                    />
                    <div class="card-body">
                      <p class="card-text">{t("pay_for_parking")}</p>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    class="btn btn-dark m-3 rounded-pill text-center"
                    style={{ fontSize: "16px", backgroundColor: "#222d2e" }}
                  >
                    {t("learn_more")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="text-center">
            <p style={{ color: "#37bf36" }}> {t("explore_our_app")} </p>
            <h2 class="fw-bold"> {t("easy_parking")} </h2>
          </div>
          <div class="container">
            <div class="mt-5">
              <div class="row m-2">
                <div class="box1 col p-5 bg-green qr-form1">
                  <p class="headint-top"> {t("scan_qr_code")} </p>
                  <div className="row">
                    {" "}
                    <img src="./img/scan.png" width="80%" />
                    <div class="d-grid gap-2 col-6 mx-auto">
                      {" "}
                      <button
                        class="btn btn-dark text-white rounded-pill"
                        width="50%"
                      >
                        {t("scan_now")}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="box2 col bg-dark fw-bold p-5 text-white qr-form2">
                  <h1 class="headint-top"> {t("find_parking")}</h1>
                  <form action="" method="post" class="accesForm">
                    <div class="mt-5">
                      <label class="">{t("location")}</label>
                      <select
                        class="form-select bg-dark "
                        aria-label="Default select example"
                      >
                        <option selected="">Montreal</option>
                        <option value="1">Laval</option>
                        <option value="2">Longueille</option>
                        <option value="3">Quebec</option>
                      </select>
                    </div>
                    <div class="mt-3">
                      <label>{t("price_range")}</label>
                      <select
                        class="form-select bg-dark"
                        aria-label="Default select example"
                      >
                        <option selected="">$0.00 - $5.00</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div class="mt-3">
                      <label>{t("parking_type")}</label>
                      <select
                        class="form-select bg-dark "
                        aria-label="Default select example"
                      >
                        <option selected="">{t("parking_type")}</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div class="mt-3">
                      <button class="btn text-white rounded-pill bg-green">
                        {t("search_parking")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section pt-5 pb-5">
          <div class="text-center">
            <p style={{ color: "#37bf36" }}> {t("sign_up_newsletter")} </p>
            <h2 class="fw-bold"> {t("parking_solved")}</h2>
            <p class=" text-center ">{t("park_smater")}</p>
            <Row className="pt-4 pb-5 justify-content-center">
              <Col className="col-10 col-md-8 text-white">
                <NewsLetter />
              </Col>
            </Row>
          </div>
        </div>
        <div class="section text-center text-white">
          <p class="text_ks">{t("download_now")}</p>
          <h2 class="text_ds" style={{ fontSize: "100%" }}>
            {t("confusing_signs")}
          </h2>
          <p class="bodyText">{t("never_circle_parking")}</p>
          <button
            class="bg-dark py-3 px-5 rounded-pill text-white m-2 "
            style={{ fontSize: "100%" }}
          >
            {t("download_now")}
          </button>
          <div>
            <img
              src="./img/smartphone-mockup-close-up.png"
              height="60%"
              width="60%"
            />
          </div>
          <div className="">
            <Contact></Contact>
          </div>
        </div>
      </body>
    </div>
  );
}

export default LandingScreen;
