import { Container } from "react-bootstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/navigation/Header";
import Footer from "./components/navigation/Footer";
// eslint-disable-next-line no-unused-vars
import firebase from "./FirebaseConfig";
import ScrollToTop from "./components/misc/ScrollToTop";

import LandingScreen from "./screens/LandingScreen";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <main>
        <Container fluid className="p-0" data-testid="main">
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<LandingScreen />} />
          </Routes>
        </Container>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
