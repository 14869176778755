import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

// Will scroll to top of page on route change to prevent page from staying at previous scroll position
const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

export default ScrollToTop;