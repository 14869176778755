/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useTranslation } from "react-i18next";
function Footer() {
  const { t } = useTranslation();
  return (
    <div class="footer border-top">
      <div class="container py-5">
        <div class="row">
          <div class="col m-3">
            <img src="/img/logo_in_footer.png" class="m-2" />
            <div class="m-2">
              <img src="/img/app_store_icon.png" class="m-2" />
              <img src="/img/google_play_icon.png" class="m-2" />
            </div>
          </div>
          <div class="col m-5">
            <div class="row">
              <div class="col footer-nav">
                <h1 class="footer-nav-title">{t("company")}</h1>
                <p>
                  <a href="#">{t("about_us")} </a>
                </p>
                <p>
                  <a href="#">{t("careers")} </a>
                </p>

                <p>
                  <a href="#">{t("terms")}</a>
                </p>
                <p>
                  <a href="#">{t("privacy")}</a>
                </p>
              </div>
              <div class="col footer-nav">
                <h1 class="footer-nav-title">{t("help")}</h1>
                <p>
                  <a href="#">{t("how_it_works")} </a>
                </p>
                <p>
                  <a href="#">{t("get_started")}</a>
                </p>
                <p>
                  <a href="#">{t("faq")} </a>
                </p>

                <p>
                  <a href="#">{t("newsletter")}</a>
                </p>
              </div>
              <div class="col footer-nav">
                <h1 class="footer-nav-title">{t("get_in_touch")}</h1>
                <div class="row">
                  <div class="col">
                    <a href="" className="me-4">
                      <i className="fab fa-facebook-f"> </i>
                    </a>
                  </div>
                  <div class="col">
                    <a href="" className=" me-4">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>

                  <div class="col">
                    <a href="" className="me-4">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "60px",
          backgroundColor: "#dddddd4f",
        }}
      >
        <p class="text-center text-dark p-3">
          © All rights reserved. ParkLive {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
}

export default Footer;
