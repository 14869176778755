import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";
import LanguageSelect from "./LanguageSelect";

import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div className="container">
      {" "}
      <nav className="navbar navbar-expand-lg bg-transparent">
        <div className="container-fluid">
          <LinkContainer to="/" className="navbar-brand clickable">
            <img src="/img/logo_in_header.png" alt="" width="100" />
          </LinkContainer>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <LinkContainer to="/">
                <Nav.Link className="">
                  <i className="fa-solid fa-house"> </i> {t("home")}
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/plantrip">
                <Nav.Link className="disabled">
                  <i className="fa-solid fa-road"></i> {t("plan_trip")}
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/maps">
                <Nav.Link className="disabled">
                  <i className="fa-solid fa-map-location-dot"></i> {t("maps")}
                </Nav.Link>
              </LinkContainer>
              {userInfo && (
                <LinkContainer to="/savedplaces">
                  <Nav.Link className="disabled">
                    <i className="fa-solid fa-bookmark"></i> {t("saved_places")}
                  </Nav.Link>
                </LinkContainer>
              )}
              <LinkContainer to="/getting-started">
                <Nav.Link className="disabled">
                  <i className="fa-solid fa-circle-exclamation"></i>{" "}
                  {t("how_it_works")}
                </Nav.Link>
              </LinkContainer>
            </ul>
          </div>

          {userInfo ? (
            <Nav className="justify-content-center">
              <NavDropdown
                title={
                  userInfo
                    ? userInfo.first_name
                      ? userInfo.first_name
                      : userInfo.name
                      ? userInfo.name
                      : userInfo.email
                      ? userInfo.email
                      : userInfo.email
                    : "Profile"
                }
                id="username"
              >
                <LinkContainer to="/profile">
                  <NavDropdown.Item>
                    {" "}
                    <i className="fas fa-user"></i> {t("profile")}
                  </NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={logoutHandler}>
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>{" "}
                  {t("logout")}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          ) : (
            <Nav className="ms-auto">
              <LinkContainer to="/login">
                <Nav.Link className="disabled">
                  <i className="fas fa-user"></i> {t("login")}
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/register">
                <Nav.Link className="disabled">
                  <i className="fas fa-user-plus"></i> {t("register")}
                </Nav.Link>
              </LinkContainer>
            </Nav>
          )}
        </div>
        <LanguageSelect></LanguageSelect>
      </nav>
    </div>
  );
}

export default Header;
