import axios from "axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_IN_STORAGE,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
} from "../constants/userConstants";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    if (email && password) {
      const { data } = await axios.post(
        "/api/users/login/",
        { username: email, password: password, email: email },
        config
      );

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem(USER_IN_STORAGE, JSON.stringify(data));
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "missing_fields",
      });
    }
  } catch (error) {
    if (/^5\d\d$/.test(error.response.status)) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "SERVER_INTERNAL_ERROR",
      });
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem(USER_IN_STORAGE);
  dispatch({ type: USER_LOGOUT });
};

export const register = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post("/api/users/register/", user, config);

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (/^5\d\d$/.test(error.response.status)) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: "SERVER_INTERNAL_ERROR",
      });
    } else {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.response.data.email
            ? error.response.data.email
            : error.message,
      });
    }
  }
};
