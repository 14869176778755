import {
  SIGN_CODE_DETAILS_FAIL,
  SIGN_CODE_DETAILS_REQUEST,
  SIGN_CODE_DETAILS_SUCCESS,
} from "../constants/signConstants";

export const ruleListReducer = (state = { rules: [] }, action) => {
  switch (action.type) {
    case SIGN_CODE_DETAILS_REQUEST:
      return { loading: true, rules: [] };

    case SIGN_CODE_DETAILS_SUCCESS:
      return {
        loading: false,
        rules: action.payload,
      };

    case SIGN_CODE_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
