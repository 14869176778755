/* eslint-disable no-unused-vars */
import React from "react";

import {
  Stack,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import FirebaseFirestoreService from "../../FirebaseFirestoreService";
function NewsLetter() {
  const { t } = useTranslation();

  const [email, setEmail] = React.useState("");

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      console.log(email);

      const response = await FirebaseFirestoreService.createDocument(
        "subscriptions",
        {
          email,
          date: new Date().toLocaleString(),
        }
      );

      alert(`Successfully subscribed to our newsletter. \n Thank you!`);
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="text-dark text-center">{t("subscribe_newsletter")}</h2>
      <Stack direction="horizontal" className="justify-content-center">
        <InputGroup
          className="mb-4 w-75 my-4"
          style={{ height: "50px", borderRadius: "50px" }}
        >
          <FloatingLabel label={t("enter_email")} className="text-dark">
            <Form.Control
              onChange={(e) => setEmail(e.target.value)}
              name={t("enter_email")}
              type="email"
              placeholder={t("enter_email")}
            />
            <Button type="submit" className="text-white prime-bg m-3">
              {t("subscribe")}
            </Button>
          </FloatingLabel>
        </InputGroup>
      </Stack>
    </form>
  );
}

export default NewsLetter;
